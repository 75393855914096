<template>
  <transition name="slide">
    <div class="vip-container">
      <h :isback="true">
        <span class="name">斗币</span>
        <span class="details" @click="showBill">明细</span>
      </h>
      <div class="main">
        <cube-scroll ref="scroll" :options="{ click: true, bounce: false }">
          <div class="balance">当前斗币余额：<span v-text="dpp" /></div>
          <div class="change-input-box">
            兑换金币：<cube-input
              type="number"
              v-model="value"
              placeholder="请输入兑换数量"
            />
            <p class="tips">
              注：100斗币可兑换1金币，请输入你需要兑换的金币数量。
            </p>
          </div>
          <p class="important">
            *斗币是斗牌TV的专属积分，您可以通过每天登陆签到、直播间打卡、发弹幕、参与竞猜等方式获得斗币。斗币可以兑换金币，金币可以用于免费开通会员、解锁金币视频、直播间送礼等玩法！
          </p>
          <p class="important">
            更多礼品兑换请添加客服微信，
            <span class="wx-txt">微信号：daipaitv</span>
          </p>
          <div class="btn">
            <cube-button @click="_dppChangeGold">兑换金币</cube-button>
          </div>
        </cube-scroll>
      </div>
      <dpp-bill ref="bill" name="斗币明细" />
    </div>
  </transition>
</template>
<script>
import h from "@/components/base/header";
import DppBill from "@/components/user/dpp-bill";
import { dppChangeGold } from "@/api/recharge";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    h,
    DppBill
  },
  data() {
    return {
      dpp: 0,
      value: ""
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.info
    })
  },
  watch: {
    user(user) {
      this.dpp = user.dppIntegral;
    }
  },
  methods: {
    ...mapMutations({
      toast: "global/SET_TOAST"
    }),
    showBill() {
      this.$refs.bill.show();
    },
    isPositiveInteger(s) {
      const re = /^[0-9]+$/;
      return re.test(s);
    },
    _dppChangeGold() {
      if (!this.isPositiveInteger(this.value) || this.value < 1) {
        this.toast("兑换金币请输入正整数");
        return;
      }
      const params = { gold: this.value };
      dppChangeGold(params).then(res => {
        if (!res) return;
        this.dpp = this.dpp - this.value * 100;
        this.$createDialog({
          type: "alert",
          title: "提示",
          content: "兑换成功",
          icon: "cubeic-alert"
        }).show();
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.vip-container
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background #fff
  font-size 14px
  color #383A40
  overflow hidden
  z-index 10
  .main
    position absolute
    top 36px
    right 14px
    bottom 0
    left 14px
    padding 20px 0
  .balance
    margin-bottom 13px
    font-size 14px
    span
      color #EDA534
  .change-input-box
    position relative
    display flex
    align-items center
    padding-bottom 30px
    margin-bottom 30px
    .tips
      position absolute
      bottom 10px
      font-size 10px
      color #F6210B
  .important
    padding-bottom 5px
    font-size 12px
    .wx-txt
      color #34D147
  .list
    display flex
    flex-wrap wrap
    li
      position relative
      display flex
      align-items center
      flex 0 0 110px
      height 69px
      border 1px solid #f0f0f0
      border-radius 4px
      margin 0 8.5px 12px 0
      &:nth-of-type(3n+0)
        margin-right 0
      &.active
        border-color #38CB4D
        .ok
          opacity 1
      .inner
        display inline-block
        width 100%
        font-size 14px
        text-align center
        .name
          color #1E88E4
        .price
          color #6B6B6B
      .ok
        position absolute
        right 0
        bottom 0
        display inline-block
        color #fff
        opacity 0
        &:after
          position absolute
          right 0
          bottom 0
          display block
          content ""
          border-width 24px 0 24px 24px
          border-style solid
          border-color transparent transparent #38CB4D
        &:before
          position relative
          z-index 1
  .pay-money
    line-height 38px
    span
      font-weight bold
      color #38CB4D
  .pay-type
    margin 23px 0 47px
    img,span
      display inline-block
      vertical-align middle
  .pay-tips
    margin-bottom 8px
    font-size 11px
    color #F6210B
    text-align center
  .btn
    margin 45px 30px 0
    .cube-btn
      border-radius 25px
      background linear-gradient(to right, #1A62DB, #2A9BFB)
      color #fff
</style>
