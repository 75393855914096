<template>
  <transition name="slide-in">
    <div v-show="showFlag" class="msg-login">
      <h :iscancel="true" @cancel="hide">
        <span class="name" v-text="name" />
      </h>
      <div v-if="list.length" class="wrap">
        <cube-scroll
          ref="scroll"
          :data="list"
          :options="options"
          @pulling-down="onPullingDown"
          @pulling-up="onPullingUp"
        >
          <ul class="list">
            <li v-for="item in list" :key="item.id" class="border-bottom-1px">
              <div class="text">
                <div class="name" v-text="status[item.changeType]" />
                <div class="date" v-text="item.createTime" />
              </div>
              <div class="num" v-text="item.changeMoney" />
            </li>
          </ul>
        </cube-scroll>
      </div>
    </div>
  </transition>
</template>
<script>
import h from "@/components/base/header";
import { getDppBillList } from "@/api/recharge";
export default {
  props: {
    name: {
      type: String,
      default: ""
    }
  },
  components: {
    h
  },
  data() {
    return {
      showFlag: false,
      list: [],
      pageNumber: 1,
      pageSize: 20,
      totalPages: 1,
      options: {
        click: true,
        // bounce: false,
        pullDownRefresh: {
          txt: "更新成功"
        },
        pullUpLoad: {
          threshold: 0,
          stopTime: 1000,
          txt: "数据加载成功"
        }
      },
      status: ["", "斗币兑换", "", "兑换金币", "", "", "", "兑换礼券"]
    };
  },
  methods: {
    show() {
      this.showFlag = true;
      this._getDppBillList();
    },
    hide() {
      this.showFlag = false;
      this.list = [];
    },
    _getDppBillList() {
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      };
      getDppBillList(params).then(res => {
        if (!res) {
          return;
        }
        if (this.pageNumber > 1) {
          this.list = this.list.concat(res.object.content);
        } else {
          this.list = res.object.content;
        }
        this.totalPages = res.object.totalPages;
      });
    },
    onPullingDown() {
      this.pageNumber = 1;
      this._getDppBillList();
    },
    onPullingUp() {
      if (this.pageNumber >= this.totalPages) {
        this.$refs.scroll.forceUpdate();
        return;
      }
      this.pageNumber++;
      this._getDppBillList();
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.msg-login
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background #fff
  z-index 11
  .wrap
    position relative
    height calc(100vh - 36px)
    margin 14px
    overflow hidden
    .list
      li
        display flex
        align-items center
        padding 5px 0
        .text
          flex 0 0 70%
          .name
            margin-bottom 5px
          .date
            font-size 12px
            color #CECECE
        .num
          flex 1
          text-align right
</style>
